import React from 'react'
import styled from "styled-components"
import { useForm } from "react-hook-form";
import Select from "../components/MaterialComponents/Select"

function StudentRegistration() {
  return (
    <Wrapper>
        <div className='student_reg_wrapper'>
            <div className='form_wrapper'>
                <h2>Student Registration</h2>
                <form>
                    <Select />
                </form>
            </div>
        </div>
    </Wrapper>
  )
}

export default StudentRegistration


const Wrapper = styled.div`
   display: grid;
   grid-template-columns: repeat(11, 1fr);
   width: 100%;
   min-height: 700px;
   height: 100vh;
   margin-top: 100px;

   .student_reg_wrapper{
    grid-area: auto/2/auto/11;
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
   }
   .form_wrapper{
    grid-area: auto/1/auto/2;
   }
`